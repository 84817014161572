import './App.css';

function App() {
  return (
    <div className="App">
        <p>Regretfully, I've taken schmodenames.com down for the time being 😢</p>
        <p>I'll have to migrate to a new hosting provider before bringing the site back up. No ETA on when that will be.</p>
        <p>In the meantime, there are other great places to play Codenames online:</p>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ul style={{ marginTop: 0 }}>
                <li><a href="https://codenames.game/">codenames.game</a></li>
                <li><a href="https://www.horsepaste.com/">horsepaste.com</a></li>
                <li><a href="https://www.codegame.cards/en">codegame.cards</a></li>
            </ul>
        </div>

        <p>Happy gaming!</p>
    </div>
  );
}

export default App;
